import { Component, Suspense, lazy, useEffect } from "react";
const QueriedKeikka = lazy(() => import("./QueriedKeikka"));
const LastSearch = lazy(() => import("./LastSearch"));
import Search from "./Search";

import "./HomePage.css";
import Loading from "../../Components/Loading";
export default function KeikkaKoodiPage(): JSX.Element {
	const urlQuery = new URLSearchParams(window.location.search).get("query");
	if (urlQuery !== null) {
		return (
			<div className="App">
				<Search />
				<Suspense fallback={<Loading />}>
					<QueriedKeikka />
				</Suspense>
				<LastSearch />
			</div>
		); //return
	} else
		return (
			<div className="Container">
				<div className="App">
					<Search />
					<LastSearch />
				</div>
			</div>
		); //else return
}
