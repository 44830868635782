import SidePanel from "../SidePanel";

export default function Header(): JSX.Element {
	const showAd = window.location.pathname == "/about" ? true : false;
	const showQuicklinks = window.location.pathname !== "/" ? true : false;
	function goHome() {
		const query = new URLSearchParams(window.location.search).get("query");
		if (query !== null) {
			localStorage.setItem("lastSearch", JSON.stringify(query));
			window.location.href = "/";
		} else {
			window.location.href = "/";
		} //else
	} //goHome

	return (
		<div className="Header">
			<a
				onClick={goHome}
				id="headerAnchor">
				<h1 id="header"> VPK työkalu </h1>
			</a>
			{/*showQuicklinks && (
				<div className="quickLinks">
					<span className="inline">
						<a href="/codesearch">
							<p className="quickLink">Tehtäväkoodin haku</p>
						</a>
					</span>

					<span className="inline">
						<a href="/messagedecoder">
							<p className="quickLink">Keikkaviestin purku</p>
						</a>
					</span>

					<span className="inline">
						<a href="unitsearch">
							<p className="quickLink">Yksikkötunnuksen haku</p>
						</a>
					</span>

					<span className="inline">
						<a href="settings">
							<p className="quickLink">Asetukset</p>
						</a>
					</span>
				</div>
			)*/}

			<SidePanel />

			{showAd && (
				<div className="androidAd">
					<h2>
						Keikkakoodi nyt myös Android-sovelluksena. <br />{" "}
						<a
							id="androidInstallLink"
							href="https://github.com/keikkakoodi/Android/releases/download/latest/keikkakoodi.apk"
							target="_blank"
							rel="noopener noreferrer">
							Asenna!
						</a>
					</h2>
				</div>
			)}
		</div>
	);
}
