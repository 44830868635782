import { useEffect } from "react";

export default function ViewSelector(): JSX.Element {
	var autoNav = window.location.pathname !== "/" ? false : true;

	useEffect(
		() => {
			var defaultView = localStorage.getItem("defaultView");
			switch (autoNav) {
				case true:
					switch (defaultView) {
						case null:
							break;
						case "0":
							break;
						case "1":
							window.location.replace("/codesearch");
							break;
						case "2":
							window.location.replace("/messagedecoder");
							break;
						case "3":
							window.location.replace("/unitsearch");
							break;
					} //switch}
					break;
			}
		}
		//useEffect(()=>{})
	); //useEffect

	function saveSettings(e: any) {
		localStorage.setItem("defaultView", e.target.value.toString);
		window.location.replace("/");
	}

	return (
		<div className="selectView">
			<h2>Valitse toiminto</h2>

			<button
				className="keikkaSearchBtn"
				id="keikkaSearchBtn"
				onClick={() => window.location.replace("/codesearch")}>
				{/*<img
					src={require("./icons/keikkaIcon.png")}
					width={"20px"}
				/>*/}
				TEHTÄVÄKOODIN HAKU
			</button>

			<br />

		{/*	<button
				className="keikkaViestiBtn"
				id="keikkaSearchBtn"
				onClick={() => window.location.replace("/messagedecoder")}>
				KEIKKAVIESTIN PURKU
			</button>

			<br /> */}

			<button
				className="keikkaSearchBtn"
				id="keikkaSearchBtn"
				onClick={() => window.location.replace("/unitsearch")}>
				YKSIKKÖTUNNUKSEN HAKU
			</button>
			<br />
			<button onClick={() => (window.location.href = "/settings")}>
				ASETUKSET
			</button>
		</div>
	);
}
