import { useState } from "react";

export default function KeikkaViestinPurku(): JSX.Element {
	const Keikat = require("../data/Keikat.json");
	const Urgencies = require("../data/Urgencies.json");

	/* 
   Keikkaviestin rakenne RLU/KRV HäKe VPK Siviilinum.:

   koodi;kiireellisyys;aika_pvm;osoite;tiedot;yksiköt
    */
	const [keikkaViesti, setKeikkaViesti] = useState("");

	const [keikkaKoodi, setKeikkakoodi] = useState() as any;
	const [urgency, setUrgency] = useState() as any;
	const [dateTime, setDateTime] = useState() as any;
	const [address, setAddress] = useState() as any;
	const [info, setInfo] = useState() as any;
	const [vehicles, setVehicles] = useState([]) as any[];
	const [hidden, setHidden] = useState(true);

	function onSubmit(e: any) {
		if (keikkaViesti != null) {
			e.preventDefault();
			var keikkaData = keikkaViesti.split(";");
			setKeikkakoodi(keikkaData[0]);
			setUrgency(keikkaData[1]);
			var dateTimeArr = keikkaData[2].split("_");
			var date = dateTimeArr[1];
			var time = dateTimeArr[0];
			setDateTime(`${date} klo ${time}`);
			setAddress(keikkaData[3]);
			setInfo(keikkaData[4]);
			setVehicles(keikkaData[5]);
			setHidden(false);
		} else {
			alert("Syötä keikkaviesti!");
		}
	}

	const queriedKeikka = Keikat.filter(
		(keikka: any) => keikka.id == keikkaKoodi
	).map((filteredKeikka: any) => {
		return filteredKeikka.desc;
	});

	const urgencyDesc = Urgencies.filter(
		(urgencyCode: any) => urgencyCode.id == urgency
	).map((filteredCode: any) => {
		return filteredCode.desc;
	});

	return (
		<div className="keikkaViestiCont">
			<form onSubmit={onSubmit}>
				<h1>Syötä keikkaviesti</h1>
				<input
					type="text"
					onChange={(e: any) => {
						setKeikkaViesti(e.target.value);
					}}
					id="searchBar"
				/>{" "}
				<br />
				<input
					type="submit"
					id="submitBtn"
				/>
			</form>
			<div
				className="purettuViesti"
				hidden={hidden}>
				<p>
					Tehtävä: {`${keikkaKoodi}; ${queriedKeikka}`} <br />
					Kiireellisyysluokka: {`${urgency}; ${urgencyDesc}`} <br />
					Lisätiedot: {info} <br />
					Yksiköt: {vehicles} <br />
					Osoite: {address} <br />
					Tehtävä lähetetty: {dateTime}
				</p>
			</div>
		</div>
	);
}
