import React, { Suspense, lazy } from "react";
import { Analytics } from "@vercel/analytics/react";
import ReactDOM from "react-dom/client";
import {
	BrowserRouter as Router,
	Routes,
	Route,
	Navigate,
} from "react-router-dom";

//Styles
import "./global.css";

//Components
import Header from "./Components/Header";
import Loading from "./Components/Loading";

import SidePanel from "./SidePanel";

import ViewSelector from "./ViewSelector";

import KeikkaKoodiPage from "./Koodihaku/koodiSearchHome/keikkaKoodiPage";

const InfoPage = lazy(() => import("./AboutPage/InfoPage"));
const NotFoundPage = lazy(() => import("./404"));

import * as serviceWorker from "./serviceWorker";
import Footer from "./Components/Footer";
import SettingsPage from "./Settings";
import KeikkaViestinPurku from "./viestinpurkaja/ViestinPurkaja";
import UnitSearch from "./tunnushaku/Home/UnitSearchHome";

const root = ReactDOM.createRoot(document.getElementById("root")!);
root.render(
	<React.StrictMode>
		<Analytics />
		<div className="container">
			<Header />
			<div className="contentWrap">
				<Router>
					<Routes>
						<Route
							index
							element={
								<Suspense fallback={<Loading />}>
									<ViewSelector />
								</Suspense>
							}
						/>

						<Route
							path="codesearch"
							element={
								<Suspense fallback={<Loading />}>
									<KeikkaKoodiPage />
								</Suspense>
							}
						/>

						<Route
							path="messagedecoder"
							element={
								<Suspense fallback={<Loading />}>
									<KeikkaViestinPurku />
								</Suspense>
							}
						/>

						<Route
							path="unitsearch"
							element={
								<Suspense fallback={<Loading />}>
									<UnitSearch />
								</Suspense>
							}
						/>

						<Route
							path="settings"
							element={
								<Suspense fallback={<Loading />}>
									<SettingsPage />
								</Suspense>
							}
						/>
						{/*						<Route
							path="about"
							element={
								<Suspense fallback={<Loading />}>
									<InfoPage />
								</Suspense>
							}
						/>

						<Route
							path="info"
							element={<Navigate to="/about" />}
						/>
*/}

						<Route
							path="*"
							element={
								<Suspense fallback={<Loading />}>
									<NotFoundPage />
								</Suspense>
							}
						/>
					</Routes>
				</Router>
			</div>
			{/*^ .contentWrap ^*/}
		</div>
		{/* .^ container ^ */}
		<Footer />
	</React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
