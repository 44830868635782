import { useState } from "react";
import InstallPWA from "./Components/InstallPWA";

export default function SettingsPage() {
	const [defaultViewSelection, setDefaultViewSelection] = useState("");

	const currDefaultView = localStorage.getItem("defaultView");

	const currDefault = currDefaultView?.toString();

	function saveSettings(e: any) {
		e.preventDefault();
		localStorage.setItem("defaultView", defaultViewSelection);
		window.location.href = "/";
	}

	return (
		<div className="settingsPage">
			<h1>Asetukset</h1>

			<p>Oletusnäkymä</p>
			<select
				name="defaultView"
				id="defaultView"
				onChange={(e) => setDefaultViewSelection(e.target.value.toString())}
				defaultValue={currDefault}>
				<option value="1">Tehtäväkoodin haku</option>
				{/*<option value="2">Keikkaviestin selventäjä</option>*/}
				<option value="3">Yksikkötunnushaku</option>
				<option value="0">Valitse aina</option>
			</select>
			<br />
			<button
				style={{ marginTop: "10px" }}
				onClick={saveSettings}>
				Tallenna asetukset
			</button>
			<br />

			<h3
				id="pwaInfo"
				style={{ marginTop: "50px" }}>
				VPK Työkalu myös mobiilisovelluksena!
				<br /> Asenna alta!
			</h3>
			<InstallPWA />
		</div>
	);
}
