import { Component, Suspense, lazy, useEffect } from "react";
const Queried = lazy(() => import("./Queried"));
import Search from "./Search";

import "./Home.css";
import Loading from "../Components/Loading";
export default function UnitSearch(): JSX.Element {
	const urlQuery = new URLSearchParams(window.location.search).get("query");

	if (urlQuery !== null) {
		return (
			<div className="App">
				<Search />
				<Suspense fallback={<Loading />}>
					<Queried />
				</Suspense>
			</div>
		); //return
	} else
		return (
			<div className="Container">
				<div className="App">
					<Search />
				</div>
			</div>
		); //else return
}
