import { useState } from "react";

export default function Search(): JSX.Element {
	const [userSearch, setUserSearch] = useState();
	const [prefix, setPrefix] = useState("null");

	const query = new URLSearchParams(window.location.search).get("query");

	function handleSearch(event: any) {
		if (userSearch != null) {
			event.preventDefault();
			if (prefix !== "null") {
				console.log(`Haku vastaanotettu:${prefix + userSearch}`);
				localStorage.setItem("lastSearch", JSON.stringify(query));
				window.location.replace(
					prefix == "X" && userSearch !== null
						? `?query=${prefix}-${userSearch}`
						: `?query=${prefix}${userSearch}`
				);
			} /*if(prefixNotNull)*/ else {
				console.log(`Haku vastaanotettu:${userSearch}`);
				localStorage.setItem("lastSearch", JSON.stringify(query));
				window.location.replace(`?query=${userSearch}`);
			}
		} else {
			prefix !== "X"
				? event.preventDefault() && alert("Syötä haettava koodi!")
				: event.preventDefault() && handleSearch(event);
		}
	}

	return (
		<div className="SearchUI">
			<form onSubmit={handleSearch}>
				<h2 id="searchTitle">Hae tehtäväkoodia:</h2>
				<br />
				<p
					id="inputRules"
					style={{ fontSize: "16px" }}>
					Hakukenttään voi kirjoittaa vain numeroita. <br />
					Tehtäväkoodin mahdollisen H-etuliitteen voi syöttää hakupalkin
					vasemmalla puolella olevasta valikosta.
				</p>
				<select
					onChange={(e: any) => setPrefix(e.target.value)}
					id="prefixMenu">
					<option value="null"></option>
					<option value="H">H</option>
					<option value="X">X</option>
				</select>

				<input
					type="number"
					onChange={(e: any) => setUserSearch(e.target.value)}
					id="searchBar"
				/>
				<br />

				<button
					id="submitBtn"
					type="submit">
					Hae
				</button>
			</form>
		</div> //.SearchUI
	);
}
