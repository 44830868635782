import { useState } from "react";

export default function Search(): JSX.Element {
	const [userSearch, setUserSearch] = useState();
	const [organization, setOrg] = useState("rescue");

	function handleSearch(event: any) {
		if (userSearch != null) {
			switch (organization) {
				case "rescue":
					event.preventDefault();
					window.location.href = `?organization=${organization}&query=${userSearch}`;
					break;
				case "emergency":
					event.preventDefault();
					window.location.href = `?organization=${organization}&query=${userSearch}`;
					break;
			}
		} else {
			event.preventDefault();
			alert("Syötä haettava tunnus!");
		}
	}

	return (
		<div className="SearchUI">
			<form onSubmit={handleSearch}>
				<h2 id="searchTitle">Hae tunnus:</h2>
				<h3>Muu alue pelastus: R(LU) ja 00xx</h3>
				<br />
				<select
					onChange={(e: any) => setOrg(e.target.value)}
					id="prefixMenu">
					<option value="rescue">R (LU)</option>
					<option value="emergency">E (LU)</option>
				</select>
				<input
					type="number"
					onChange={(e: any) => setUserSearch(e.target.value)}
					id="searchBar"
				/>
				<br />

				<button
					id="submitBtn"
					type="submit">
					Hae
				</button>
			</form>
		</div> //.SearchUI
	);
}
