import { useState } from "react";
import SlidingPanel from "react-sliding-side-panel";

import ViewSelector from "./ViewSelector";

export default function SidePanel(): JSX.Element {
	const [openPanel, setOpenPanel] = useState(false);

	return (
		<div className="sidePanel">
			<button onClick={() => setOpenPanel(true)}>Valikko</button>

			<SlidingPanel
				type={"left"}
				isOpen={openPanel}
				size={100}>
				<div
					className="panel"
					style={{ backgroundColor: "#b00b1e", height: "80vh" }}>
					<h1>VPK Työkalu</h1>

					<ViewSelector></ViewSelector>

					<button onClick={() => setOpenPanel(false)}>Sulje</button>
				</div>
			</SlidingPanel>
		</div>
	);
}
